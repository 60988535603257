// 会议管理
import request from "@/utils/request"
import FileSaver from "file-saver"

export default {
    get(params) {
        return request.get(params.apiName, { params })
    },
    post(data) {
        return request.post(data.apiName, data.data)
    },
    list(params) {
        return request.get(params.apiName, { params })
    },
    create(data) {
        return request.post(data.apiName + "/create", data)
    },
    detail(params) {
        return request.get(params.apiName + "/detail", { params })
    },
    update(data) {
        return request.post(data.apiName + "/update", data)
    },
    remove(data) {
        return request.post(data.apiName + "/remove", data)
    },
    revoke(data) {
        return request.post(data.apiName + "/revoke", data)
    },
    // 下载文件
    async download(params) {
        const result = await request.get(params.apiName, {
            responseType: "blob",
            params,
            raw: true,
        })
        const fileName = result.headers["content-disposition"].match(
            /filename=(.*)/i
        )[1]
        const blob = new Blob([result.data], {
            type: "application/octet-stream",
        })
        FileSaver.saveAs(blob, decodeURIComponent(fileName))
    },

    //以下是独立功能接口
    getBusinessTypeList(params) {
        return request.get("/market/businessType/allList", { params })
    },
    getAllCommodityList() {
        return request.get("/market/commodity/listAll")
    },
    // 发送
    sendSock() {
        return request.get("/bigScreen/sendSock")
    },
    // 近七日交易额
    nearlySevenDaysTransactionAmount() {
        return request.get("/statistics/nearlySevenDaysTransactionAmount")
    },
    // 获取每日菜价列表
    getDailyPriceList() {
        return request.get("/market/dailyPrice")
    },
    getConfig() {
        return request.get("/config")
    },
}