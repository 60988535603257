<template>
  <div class="app_con">
    <app-list
      :remote="remote"
      :props="props"
      :formProps="formProps"
      :searchQuery="searchQuery"
      :searchControl="searchControl"
      :apiName="apiName"
      :editTitle="'管理宝典'"
      :editBtnText="'修改'"
      @cellClick="cellClick"
    >
      <div class="search" slot="tool">
        <el-input
          clearable
          style="width: 280px"
          placeholder="请输入文件名称"
          v-model="searchQuery.titel"
          class="searchInput"
        >
          <template slot="prepend">文件名称</template>
        </el-input>
        <el-input
          clearable
          style="width: 280px"
          placeholder="请输入发布单位"
          v-model="searchQuery.unitName"
          class="searchInput"
        >
          <template slot="prepend">发布单位</template>
        </el-input>
      </div>
    </app-list>
    <el-dialog title="管理宝典" :visible.sync="showContentVisible">
      <div v-html="content"></div>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      remote: api.common,
      apiName: "/baoDian/manage",
      searchQuery: { titel: "", unitName: "" },
      searchControl: {
        firstSoltSpan: [5, 5, 5, 5],
        secondSoltSpan: [0, 0, 0, 0],
        thirdSoltSpan: [17, 17, 17, 17],
      },
      props: [
        {
          label: "文件名称",
          prop: "titel",
          align: "center",
        },

        {
          label: "颁布单位",
          prop: "unitName",
          align: "center",
        },
        {
          label: "发文字号",
          prop: "textNo",
          align: "center",
        },
        {
          label: "发布日期",
          prop: "releaseDate",
          align: "center",
        },
        {
          label: "提交单位",
          prop: "submitUnit",
          align: "center",
        },

        {
          label: "提交人",
          prop: "submitPerson",
          align: "center",
        },
        {
          label: "提交日期",
          prop: "createTime",
          align: "center",
        },
      ],
      formProps: [
        {
          label: "文件名称",
          prop: "titel",
          type: "input",
        },

        {
          label: "颁布单位",
          prop: "unitName",
          type: "input",
        },

        {
          label: "发文字号",
          prop: "textNo",
          type: "input",
        },
        {
          label: "发布日期",
          prop: "releaseDate",
          type: "date",
        },
        {
          label: "提交单位",
          prop: "submitUnit",
          type: "input",
        },
        {
          label: "提交人",
          prop: "submitPerson",
          type: "input",
        },
        {
          label: "内容",
          type: "editor",
          isFull: false,
          prop: "content",
        },
      ],
      showContentVisible: false,
      content: "",
    };
  },
  created() {},
  methods: {
    cellClick(e) {
      const { row, column } = e;
      if (column.property == "titel") {
        this.showContentVisible = true;
        this.content = row.content;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.searchInput {
  width: 20%;
  margin-right: 20px;
}
.app_con {
  /deep/ .el-form-item {
    margin-bottom: 10px;
  }
  /deep/ .el-table__body {
    tr {
      cursor: pointer;
    }
  }
}
</style>
