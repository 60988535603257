// 整个API
import user from './user';
import dictionary from './dictionary';
import common from './common';
import uploadImage from './uploadImage';
import businessType from './businessType';
import contract from './contract';
import permissionVue from './permissionVue';
import role from './role';
import bigScreenMgr from './bigScreenMgr';



export default {
  user,
  dictionary,
  common,
  uploadImage,
  businessType,
  contract,
  permissionVue,
  role,
  bigScreenMgr,

};
